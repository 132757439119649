import React, { useEffect, useState } from "react";
import axios from "axios";
import leaderboardIcon from './img/leaderboardicon.svg';
import './index.css'
import { useNavigate, useSearchParams } from "react-router-dom";
import { LeaderboardScoreTable } from "./components/LeaderboardScoreTable";
import { LeaderboardHeading } from "./Leaderboard.styles";
import AdministrationContainer from "./components/AdministrationContainer";
import { AdminBlueContainer, AdminBlueInnerContainer } from "./styles";

const Admin = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    const [gameId] = useState(searchParams.get('gameId'));
    const [game, setGame] = useState({})
    const [leaderboard, setLeaderboard] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/getLeaderboard?gameId=${gameId}`).then(
            res => {
                setLeaderboard(res.data.result);
            }
        ).catch(error => {
            console.log(error);
            navigate('/error');
        });

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/getGameById?gameId=${gameId}`).then(
            res => {
                setGame(res.data.result);
            }
        ).catch(error => {
            console.log(error);
            navigate('/error');
        });

        setSearchParams('');
    }, [setLeaderboard, gameId, setSearchParams, navigate]);

    return (
        <AdministrationContainer>
            <AdminBlueContainer >
                <AdminBlueInnerContainer style={{ maxWidth: 580 }}>
                    <img src={leaderboardIcon} alt='leaderboard trophy icon' width={160} />
                    <LeaderboardHeading>Leaderboard for {game.gameName}</LeaderboardHeading>
                    <LeaderboardScoreTable leaderboard={leaderboard} />
                </AdminBlueInnerContainer>
            </AdminBlueContainer>
        </AdministrationContainer>
    )

};

export default Admin;
