import React from "react";
import blackOptiverlogo from "../img/blackoptiverlogo.png";
import backgroundDecoration from '../img/decoration9.png';
import { ExitOutline, } from "react-ionicons";
import { AdminContainer, AdminContentContainer, AdminContentInnerContainer, AdminContentSectionRow, AdminFooterSection, AdminHeaderContainer, AdminHeaderInnerContainer, XlHeading, } from "../styles";


const AdministrationContainer = ({ children, pageHeader }) => {

    return (
        <AdminContainer>

            <AdminHeaderContainer>
                <AdminHeaderInnerContainer>
                    <img src={blackOptiverlogo} height={32} align='right' alt='Optiver logo' />
                    <ExitOutline color={'#213253'} height="32px" width="32px" onClick={() => alert('Sign out!')} />
                </AdminHeaderInnerContainer>
            </AdminHeaderContainer>

            <AdminContentContainer>
                <AdminContentInnerContainer>

                    <AdminContentSectionRow>
                        <XlHeading>{pageHeader}</XlHeading>
                        <img src={backgroundDecoration} height={103} alt='background decoration' style={{ marginTop: 20, }} />
                    </AdminContentSectionRow>

                    {children}

                    <AdminFooterSection>

                        <img src={backgroundDecoration} height={103} alt='background decoration' style={{ marginTop: 20, }} />
                    </AdminFooterSection>

                </AdminContentInnerContainer>
            </AdminContentContainer>
        </AdminContainer>
    )

};

export default AdministrationContainer;

