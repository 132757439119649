import React, { useEffect } from "react";
import { Container, Divider, Grid, Segment, Button } from "semantic-ui-react";
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import blueBackgroundOptiverlogo from "./img/bluebackgroundoptiverlogo.png";
import decoration12 from "./img/decoration12.png";
import './thanks.css';
import { Decoration12, Thp1 } from "./styles";

const buttonStyles = {
    background: "#ff3300",
    borderRadius: 60,
    width: 250,
    height: 60,
    margin: "auto",
    color: "#000000",
    outline: "none",
    border: "none",
    cursor: "pointer",
}

const textAnimationContainer = {
    hidden: { opacity: 0, paddingBottom: 0 },
    visible: { opacity: 1, paddingBottom: 60 }
}

const Thanks = () => {
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/updateScore/`, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }, params: { insertedId: location.state.insertedId, playerName: location.state.playerName, score: location.state.score.toString(), }
        }).then(
            res => {
                let mydata = res.data
            }
        ).catch({

        })
    }, []);
    const routeChange = () => {

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/updateScore/`, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }, params: { insertedId: location.state.insertedId, playerName: location.state.playerName, score: location.state.score.toString() }
        }).then(
            res => {
                let mydata = res.data
            }
        ).catch({

        })

        let path = '/';
        navigate(path);
    }

    return (
        <Container style={{
            paddingTop: 0.1 * window.innerHeight, paddingBottom: 0.1 * window.innerHeight,
            paddingLeft: 0.01 * window.innerWidth, paddingRight: 0.01 * window.innerWidth
        }}>
            <style>
                {`
                html,body{
                    background-color: rgb(235, 244, 253);
                }
            `}
            </style>
            <Grid columns='equal'>
                <Segment class="segmentStyle" style={{
                    borderRadius: "2em",
                    borderColor: 'white',
                    borderWidth: '1px',
                    boxShadow: '2px 1px 50px 3px #cccccc '
                }}>                    <Grid.Row columns='equal'>
                        <Grid.Column >

                            <Grid style={{
                                width: '102.9%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 14 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <span class="notosansjpblack"  >Optiver Trading Game</span>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />

                            <thp>
                                <Grid style={{ marginTop: 0 }}>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column>
                                            <Grid.Row>
                                                <thp2 style={{ paddingTop: 60 }}>
                                                    <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                                                        transition={{ duration: 1 }}
                                                        style={{ 'align-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'min-height': 10 }}>
                                                        <motion.span style={{ 'font-size': 49, 'font-weight': '900', 'text-align': 'center' }}>
                                                            <span class="notosansjpblack" style={{ color: '#1c3255' }}>
                                                                Thanks for playing!
                                                            </span>
                                                        </motion.span>
                                                    </motion.div>
                                                </thp2>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid relaxed style={{ marginBot: 0 }}>
                                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                                        <Grid.Column width={4}>

                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ paddingTop: 14 }}>
                                                            <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                                                                transition={{ duration: 1.5 }}
                                                                style={{ 'align-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'min-height': 10 }}>
                                                                <motion.span style={{ 'font-size': 24, 'font-weight': 'bold', 'text-align': 'center' }}>

                                                                    <Grid>
                                                                        <Grid.Row columns={3}>
                                                                            <Grid.Column width={9} textAlign="right" style={{ paddingTop: 14 }}>
                                                                                <span class="notosansjpblack" style={{ color: '#1c3255' }}>
                                                                                    FINAL SCORE:
                                                                                </span>
                                                                            </Grid.Column>
                                                                            <Grid.Column textAlign="left">
                                                                                <Button color='black' basic
                                                                                    style={{
                                                                                        borderRadius: '0.5em', textAlign: 'center', paddingTop: 16
                                                                                    }}>
                                                                                    <span class="notosansjpblack" style={{ textAlign: 'center', inlineTextAlign: 'center', fontSize: 28, color: '#1c3255' }} >
                                                                                        {location.state.score}
                                                                                    </span>
                                                                                </Button>
                                                                            </Grid.Column>
                                                                        </Grid.Row>

                                                                    </Grid>

                                                                </motion.span>

                                                            </motion.div>

                                                        </Grid.Column>
                                                        <Grid.Column width={4}>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid relaxed style={{ marginBot: 0 }}>
                                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                                        <Grid.Column width={4}>

                                                        </Grid.Column>
                                                        <Grid.Column width={9}>
                                                            <thp3 style={{ paddingBottom: 14 }}>
                                                                <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                                                                    transition={{ duration: 1.5 }}
                                                                    style={{ 'align-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'min-height': 10 }}>
                                                                    <motion.span style={{ 'font-size': 24, 'font-weight': 'bold', 'text-align': 'center' }}>
                                                                        <span class="notosansjpblack" style={{ 'textAlign': 'center', color: '#1c3255' }}>
                                                                            Check the leaderboard to see how you did.
                                                                        </span>
                                                                        <spaninvisibleintegertext>
                                                                            Check the leaderboard to see how you did.
                                                                        </spaninvisibleintegertext>
                                                                        <spaninvisibleintegertext>
                                                                            Check the leaderboard to see how you did.
                                                                        </spaninvisibleintegertext>
                                                                    </motion.span>
                                                                </motion.div>

                                                            </thp3>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>

                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid relaxed >
                                                    <Grid.Row>
                                                        <Grid.Column width={5}>

                                                        </Grid.Column>
                                                        <Grid.Column width={6}>
                                                            <thp4 style={{ paddingBottom: 16 }}>
                                                                <Grid relaxed>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={2}>
                                                                        </Grid.Column>

                                                                        <Grid.Column width={2}>
                                                                            <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                                                                                transition={{ duration: 1.5 }}
                                                                                style={{ 'align-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'min-height': 10 }}>
                                                                                <motion.button onClick={routeChange} style={buttonStyles} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.5 }}>
                                                                                    <buttonSpan class="notosansjplight" style={{ fontWeight: 300, color: "#ffffff", fontSize: '22px' }}>Play Again</buttonSpan>
                                                                                </motion.button>
                                                                            </motion.div>

                                                                        </Grid.Column>
                                                                        <Grid.Column width={2}>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </thp4>
                                                        </Grid.Column>
                                                        <Grid.Column width={5}>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Row>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </thp>
                        </Grid.Column>
                    </Grid.Row>
                    <Decoration12>
                        <img src={decoration12} width="25%" align='right' style={{ marginRight: -14, marginBottom: -14 }} >
                        </img>
                    </Decoration12>
                </Segment>
            </Grid>
        </Container>
    )

}

export default Thanks;