import React, { useEffect, useState } from "react";
import axios from "axios";
import { ChevronDownOutline, ChevronUpOutline } from "react-ionicons";
import { CSVLink } from "react-csv";
import { Checkbox } from 'semantic-ui-react';
import exportIcon from './img/exportIcon.svg';
import copyIcon from './img/copyIcon.svg';
import { AccordionContainer, ActiveGamesHeading, ContentSectionColumn, CopyBox, CreateGameContainer, GameHeading, Input, InputButton, InputContainer, LHeading, MHeading, SHeading } from "./Admin.styles";
import { LeaderboardScoreTable } from "./components/LeaderboardScoreTable";
import AdministrationContainer from "./components/AdministrationContainer";
import { useNavigate } from "react-router-dom";


const Admin = () => {

    const [gamesList, setGamesList] = useState([]);
    const [isGameUpdated, setIsGameUpdated] = useState(false);
    const [newGameName, setNewGameName] = useState('');
    const [gameNameValidationError, setGameNameValidationError] = useState('');
    const [generatedGameId, setGeneratedGameId] = useState('');
    const [usersList, setUsersList] = useState();
    const [copiedAlertDetails, setCopiedAlertDetails] = useState({ showCopiedAlert: false, leftLocation: 0, topLocation: 0 });
    let navigate = useNavigate();

    useEffect(() => {
        // axios.get(`${process.env.REACT_APP_BACKEND_URL}/getGames/`).then(
        //     res => {
        //         setGamesList(res.data.result);
        //     }
        // ).catch(error => {
        //     console.log(error);
        //     navigate('/error');
        // });

        // axios.get(`${process.env.REACT_APP_BACKEND_URL}/getAllUsers/`).then(
        //     res => {
        //         setUsersList(res.data.result);
        //     }
        // ).catch(error => {
        //     console.log(error);
        //     navigate('/error');
        // });

        setIsGameUpdated(false);
    }, [isGameUpdated, setIsGameUpdated, navigate]);

    const onCopy = (e, value) => {
        if (e.clientX && e.clientY) {
            setCopiedAlertDetails({ showCopiedAlert: true, leftLocation: e.clientX, topLocation: e.clientY });
            navigator.clipboard.writeText(value);

            const timeOut = () => new Promise(resolve => setTimeout(resolve, 1200));

            timeOut().then(() => setCopiedAlertDetails({ showCopiedAlert: false }));
        }
    };


    const onGenerateGame = () => {

        if (newGameName === '') {
            setGameNameValidationError('please enter a valid game name');
        } else {
            setGameNameValidationError('');
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/creategame/`, {
                params: { gameName: newGameName }
            }).then(
                res => {
                    setIsGameUpdated(true);
                    setGeneratedGameId(res.data.insertedId);
                }
            ).catch(error => {
                console.log(error);
                navigate('/error');
            });

        }
    }

    return (
        <AdministrationContainer pageHeader={'Manage Games & Leaderboards'}>

            <ContentSectionColumn>
                <LHeading>
                    Create a new game
                </LHeading>

                <CreateGameContainer>
                    <SHeading>
                        Type in a game nickname
                    </SHeading>
                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                        <InputContainer>
                            <Input name="gameNameInput" placeholder="Example: Moola" value={newGameName}
                                onChange={e => { setGameNameValidationError(''); setNewGameName(e.target.value) }} />
                        </InputContainer>
                        <InputButton onClick={onGenerateGame}>Generate game Id</InputButton>
                    </div>
                    <div style={{ color: 'red', height: 20 }}>{gameNameValidationError}</div>
                    <SHeading>
                        Copy game Id to share
                    </SHeading>
                    <CopyBox style={{
                        justifyContent: 'space-between',
                        backgroundColor: '#F6F5F5',
                        width: 280
                    }} onClick={(e) => onCopy(e, generatedGameId)}>
                        <div>
                            {generatedGameId}
                        </div>
                        <img src={copyIcon} alt='copy game id' />
                    </CopyBox>
                </CreateGameContainer>
            </ContentSectionColumn>

            <ContentSectionColumn>
                <ActiveGamesHeading>
                    Active Games
                </ActiveGamesHeading>
            </ContentSectionColumn>

            {gamesList.length > 0 && gamesList.map((game) => {
                if (!game.archived)
                    return <GameAccordionItem key={game._id} game={game} users={usersList} onCopy={onCopy} setIsGameUpdated={setIsGameUpdated} isGameUpdated={isGameUpdated} />
                else return <div key={game._id}></div>
            })}

            <ContentSectionColumn style={{ marginTop: 4 }}>
                <ActiveGamesHeading>
                    Deactivated Games
                </ActiveGamesHeading>
            </ContentSectionColumn>

            <div style={{ marginBottom: 50 }}>
                {gamesList.length > 0 && gamesList.map((game) => {
                    if (game.archived)
                        return <GameAccordionItem key={game._id} game={game} users={usersList} onCopy={onCopy} setIsGameUpdated={setIsGameUpdated} isGameUpdated={isGameUpdated}
                            navigate={navigate} />
                    else return <div key={game._id}></div>
                })}

            </div>

            {copiedAlertDetails.showCopiedAlert && <CopiedAlert copiedAlertDetails={copiedAlertDetails} />}

        </AdministrationContainer>
    )

};

export default Admin;

const CopiedAlert = ({ copiedAlertDetails }) => {
    if (copiedAlertDetails) {
        return (<div style={{
            backgroundColor: '#1C3255',
            color: 'white',
            padding: 8,
            borderRadius: 5,
            position: 'absolute',
            left: copiedAlertDetails.leftLocation,
            top: copiedAlertDetails.topLocation - 40
        }}>Copied!</div>)
    }
    else return <></>
}

const GameAccordionItem = ({ game, users, onCopy, setIsGameUpdated, isGameUpdated, navigate }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isActivated, setIsActivated] = useState(!game.archived);
    let leaderboard = users ? users.filter(user => user.gameId === game._id) : [];
    const gameLink = `${process.env.REACT_APP_FRONTEND_URL}/Leaderboard?gameId=${game._id}`;
    const csvHeaders = [{ label: "User ID", key: "userId" },
    { label: "User Name", key: "playerName" },
    { label: "Result", key: "result" }];

    const changeActivationStatus = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/setGameActivationStatus/`, {
            params: { gameId: game._id, archived: isActivated }
        }).then(
            res => {
                setIsActivated(!isActivated);
                setIsGameUpdated(!isGameUpdated);
            }
        ).catch(error => {
            console.log(error);
            navigate('/error');
        });
    }


    return (
        <ContentSectionColumn style={{ marginTop: 4 }}>
            <AccordionContainer>
                <div style={{ display: 'flex', }} onClick={() => setIsExpanded(!isExpanded)} >
                    {isExpanded ?
                        <ChevronUpOutline height="25px" width="25px" style={{ display: 'flex' }} /> : <ChevronDownOutline height="25px" width="25px" style={{ display: 'flex' }} />
                    }
                    <GameHeading>
                        {game.gameName}
                    </GameHeading>

                </div>

                {isExpanded && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1, marginRight: 20 }}>
                        <MHeading >Leaderboard</MHeading>
                        <div style={{ display: 'flex' }}>
                            <LeaderboardScoreTable leaderboard={leaderboard} />
                        </div>
                    </div>

                    <div style={{ flex: 1 }}>
                        <MHeading>Controls</MHeading>

                        <div style={{ display: 'flex', marginBottom: 20, paddingLeft: 8 }}>
                            <Checkbox toggle checked={!isActivated} onChange={changeActivationStatus} />
                            <SHeading style={{ paddingLeft: 8 }}>{isActivated ? 'Deactivate game' : 'Reactivate game'}</SHeading>
                        </div>

                        {isActivated && <>
                            <SHeading style={{ marginTop: 24, }}>
                                Leaderboard page link
                            </SHeading>
                            <CopyBox style={{
                                overflow: 'hidden',
                                backgroundColor: '#F6F5F5',
                                width: window.innerWidth < 400 ? 245 : '100%',
                            }} onClick={(e) => onCopy(e, gameLink)}>
                                <img src={copyIcon} style={{ marginRight: 8 }} alt='copy game link' />
                                <div>
                                    {gameLink}
                                </div>

                            </CopyBox>
                        </>}

                        {isActivated && <><SHeading style={{ marginTop: 16, }}>
                            Game ID
                        </SHeading>
                            <CopyBox onClick={(e) => onCopy(e, game._id)}>
                                <img src={copyIcon} style={{ marginRight: 8, display: 'flex', }} alt='copy game id' />
                                <div >
                                    {game._id}
                                </div>

                            </CopyBox>
                        </>
                        }

                        <SHeading style={{ marginTop: 16 }}>
                            Export to CSV
                        </SHeading>
                        <CopyBox style={{ textDecoration: 'underline' }}>
                            <img src={exportIcon} style={{ marginRight: 8 }} alt='export-csv' />
                            <CSVLink data={leaderboard} target="csvFileName" filename={game.gameName} headers={csvHeaders}>{game.gameName + '.csv'} </CSVLink>
                        </CopyBox>
                    </div>
                </div>}
            </AccordionContainer>
        </ContentSectionColumn>
    )
}

const nth = (n) => {
    return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
}
