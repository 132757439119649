
import React from "react";
import { CaretUpOutline, CaretDownOutline } from "react-ionicons";
import { Table, } from "../Admin.styles";

export const LeaderboardScoreTable = ({ leaderboard }) => {

    leaderboard.sort((a, b) => b['result'] - a['result']);
    const limitedLeaderboard = leaderboard.slice(0, 10);

    return (
        <Table>
            <thead >
                <tr >
                    <th style={{ borderTopLeftRadius: 4, borderRight: '1px solid #C8C8C8', borderBottomLeftRadius: limitedLeaderboard.length > 0 ? 0 : 4 }}>Rank</th>
                    <th style={{ borderRight: '1px solid #C8C8C8' }} >Player Name</th>
                    <th style={{ borderTopRightRadius: 4, borderBottomRightRadius: limitedLeaderboard.length > 0 ? 0 : 4 }}>Score</th>
                </tr>
            </thead>
            <tbody >
                {limitedLeaderboard.map((user, index) => <tr key={user._id}>
                    <td style={{ borderRight: '1px solid #C8C8C8', }}>
                        {index + 1 + nth(index + 1)}
                    </td>
                    <td style={{ borderRight: '1px solid #C8C8C8', }}>
                        {user.playerName}
                    </td>
                    <td style={{ display: 'flex' }}>
                        {user.result > 0 ?
                            <CaretUpOutline color='#00D084' style={{ display: 'flex' }} /> :
                            <CaretDownOutline color='#FF3300' style={{ display: 'flex' }} />}{user.result > 0 ? `+${user.result}` : user.result}
                    </td>
                </tr>)}
            </tbody>
        </Table>
    );
}

const nth = (n) => {
    return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
}