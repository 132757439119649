import React from "react";
import pageError from './img/PageError.svg';
import error from './img/Error.svg';
import './index.css'
import AdministrationContainer from "./components/AdministrationContainer";
import { AdminBlueContainer, AdminBlueInnerContainer } from "./styles";
import { ErorInnerContainer, ErorText, ErrorHeading } from "./Error.styles";

const ErrorPage = () => {

    return (
        <AdministrationContainer>

            <AdminBlueContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
                <ErrorHeading>Sorry, we couldn't find what you were looking for</ErrorHeading>

                <AdminBlueInnerContainer style={{ paddingBottom: 100, paddingTop: 100, }} >
                    <img src={pageError} alt='' />
                    <ErorInnerContainer>
                        <img src={error} alt='' />
                        <ErorText>Unfortunately something went wrong with this query. Please contact your administrator. </ErorText>
                    </ErorInnerContainer>
                </AdminBlueInnerContainer>
            </AdminBlueContainer>

        </AdministrationContainer>
    )
}

export default ErrorPage;