import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Container, Grid, GridRow, GridColumn, Button, Modal, Segment, Divider, Dropdown, Icon } from "semantic-ui-react";
import axios from 'axios';
import {
    AreaChart,
    YAxis,
    CartesianGrid,
    Area,
} from "recharts";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import './trading.css';
import useSound from 'use-sound';
import buySound from './sounds/buy.wav';
import sellSound from './sounds/sell.wav';
import uptickSound from './sounds/uptick.wav';
import downtickSound from './sounds/downtick.wav';
import { useNavigate, useLocation } from 'react-router-dom';
import GaugeChart from 'react-advanced-gauge-chart'
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import sc0 from "./img/sc0.png";
import sc1 from "./img/sc1.png";
import blueBackgroundOptiverlogo from "./img/bluebackgroundoptiverlogo.png";
import { Bluebackgroundoptiverlogo, Lasttutorial, Notosansjpblack, Numberinbox, Numberinpositionbox, Ptra, Ptra2, Ptra3, Ptra5, Ptraspaninvisibleintegertext, Recordpartbuy, Recordpartsell, Spp1, Thp1 } from "./styles";

const gameLength = 152.49;
// const gameLength = 27.49;

let curTime = 0;
let tradeRecordLength = 0;
let curRound = 0;

const positionOptions = [
    { key: 5, text: '5', value: 5 },
    { key: 4, text: '4', value: 4 },
    { key: 3, text: '3', value: 3 },
    { key: 2, text: '2', value: 2 },
    { key: 1, text: '1', value: 1 },
    { key: 0, text: '0', value: 0 },
    { key: -1, text: '-1', value: -1 },
    { key: -2, text: '-2', value: -2 },
    { key: -3, text: '-3', value: -3 },
    { key: -4, text: '-4', value: -4 },
    { key: -5, text: '-5', value: -5 },
]

const Trading = () => {

    const signal0Color = useRef();
    const signal1Color = useRef();
    const signal2Color = useRef();
    const initTrade = useRef(true);
    const justThroughSecond = useRef(false);
    const tempOrders = useRef([]);
    const [secondOpen, setSecondOpen] = useState(false)
    const positionValueFromEvent = useRef();
    const eventConfirmClicked = useRef();
    const launch = useRef();
    const [values, setValues] = useState([{ "time": 0, "stock": 0 }])
    const dialvalue0 = useRef(0)
    const dialvalue1 = useRef(0)
    const dialvalue2 = useRef(0)
    const dialvalue3 = useRef(0)
    const dialvalue4 = useRef(0)

    const initRecords = []
    const [records, setRecords] = useState([...initRecords]);
    const [position, setPosition] = useState(0);
    const [orders, setOrders] = useState([]);
    const [profitsHistory, setProfitsHistory] = useState([]);

    const [frozen, setFrozen] = useState(0);
    const [firstModalDone, setFirstModalDone] = useState(0);
    const [frozenBoolean, setFrozenBoolean] = useState(false);
    const [timePassed, setTimePassed] = useState(30);
    const [secondTimePassed, setSecondTimePassed] = useState(5);

    const [tutorialPage0, setTutorialPage0] = useState(false);
    const [tutorialPage1, setTutorialPage1] = useState(false);
    const [tutorialPage2, setTutorialPage2] = useState(false);
    const [tutorialPage3, setTutorialPage3] = useState(false);
    const finishTutorial = useRef(false);

    const [playBuy] = useSound(buySound);
    const [playSell] = useSound(sellSound);
    const [playUp] = useSound(uptickSound);
    const [playDown] = useSound(downtickSound);

    const yAxisLowerBound = useRef(-15);
    const yAxisUpperBound = useRef(15);
    const profitsYAxisLowerBound = useRef(-15);
    const profitsYAxisUpperBound = useRef(15);
    //store last uptick and downtick sound play timeframe according to time
    const lastRing = useRef([{ "time": 0, "stock": 100 }]);
    //0 for up, 1 for down
    const lastRingType = useRef(0);
    const oscillateFlag = useRef(false);

    const [profitsTrack, setProfitsTrack] = useState([0]);
    const [eventContent, setEventContent] = useState([]);
    const [eventResult, setEventResult] = useState();
    const fetchedResult = useRef();
    const fetchedEvents = useRef();
    const [firstFetch, setFirstFetch] = useState(1);
    const [isHidden, setIsHidden] = useState(true);

    let eventFinished = 0;
    let record;
    let recordsForDisplay = records.map((x) => x);

    let navigate = useNavigate();
    const location = useLocation();


    const chartStyle0 = {
        height: 120, width: 240,
    };

    const chartStyle1 = {
        height: 120, width: 240,
        paddingTop: 10
    };

    const Gauge0 = useMemo(() => {

        return (<GaugeChart id="gauge-chart0"
            nrOfLevels={5}
            colors={["#FB361D", "#DA6E37", "#EEB94A", "#578799", "#509F5B"]}
            arcWidth={0.2}
            percent={((dialvalue3.current + 100) / 200)}
            arcPadding={0.01}
            style={chartStyle0}
            needleColor={'#1c3255'}
            hideText={true}
            cornerRadius={0}
        />)
    })

    const Gauge1 = useMemo(() => {
        return (
            <GaugeChart id="gauge-chart1"
                nrOfLevels={5}
                colors={["#FB361D", "#DA6E37", "#EEB94A", "#578799", "#509F5B"]}
                arcWidth={0.2}
                percent={((dialvalue4.current + 100) / 200)}
                arcPadding={0.01}
                style={chartStyle1}
                needleColor={'#1c3255'}
                hideText={true}
                cornerRadius={0}
            />)
    })

    const config = {
        tex2jax: {
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    const showEventContents = () => {
        let resultArray = [];
        let tempStringArray;
        for (let i = 0; i < eventContent.length; i++) {
            if (eventContent[i].includes('myequation0')) {
                tempStringArray = eventContent[i].split('myequation0')
                let partA = tempStringArray[0]
                let partB = tempStringArray[1]
                resultArray.push(
                    <span inline='true' >
                        {partA}
                        <MathJaxContext config={config} version={3} inline='true'>
                            <MathJax inline='true'>
                                {"\\(e^{\\Pi} > \\Pi^{e} \\)"}
                            </MathJax>
                        </MathJaxContext >
                        {partB}
                    </span>
                )
            } else if (eventContent[i].includes('myequation1')) {
                resultArray.push(
                    <span style={{ textAlign: 'center ' }}>
                        <MathJaxContext config={config} version={3} style={{ display: 'inline', textAlign: 'center' }}>
                            <MathJax style={{ textAlign: 'center ' }}>
                                {"\\(\\frac{1}{2} \\times \\frac{3}{4} \\times \\frac{5}{6} \\times \\frac{7}{8} \\times ... \\frac{99}{100} \\)"}
                            </MathJax>
                        </MathJaxContext >
                    </span>
                )
            } else if (eventContent[i].includes('myequation2')) {
                // tempStringArray = eventContent[i].split('myequation2')
                resultArray.push(
                    <span style={{ textAlign: 'center ' }}>
                        <MathJaxContext config={config} version={3} style={{ display: 'inline', textAlign: 'center' }}>
                            <MathJax style={{ textAlign: 'center ' }}>
                                {"\\(3^{100} \\times 2^{80} \\)"}
                            </MathJax>
                        </MathJaxContext >
                    </span>
                )
            } else {
                resultArray.push(<span >{eventContent[i]}</span>);
                resultArray.push(<br />);
            }
        }

        return (
            <ptrae>
                <span style={{ marginLeft: 50, marginRight: 50, color: '#1c3255', marginTop: -40 }}>
                    {resultArray}
                </span>
                <spaninvisibleintegertext >
                    abc
                </spaninvisibleintegertext >

                <span style={{ marginLeft: 50, marginRight: 50, fontSize: 22, color: '#1c3255' }}>
                    What position do you want?
                </span>
            </ptrae>
        )
    }

    function fixingColor(sliderValue) {
        let colorResult = '';
        let r = 0;
        let g = 0;
        let b = 0;
        let r0 = 0;
        let g0 = 0;
        let b0 = 0;
        if (sliderValue >= -100 && sliderValue <= -60) {
            r0 = 255;
            g0 = 51;
            b0 = 0;
            let percent = (sliderValue + 100) * 2.5 / 100
            r = r0 + percent * (218 - r0);
            g = g0 + percent * (110 - g0);
            b = b0 + percent * (55 - b0);
        } else if (sliderValue > -60 && sliderValue <= -20) {
            r0 = 218;
            g0 = 110;
            b0 = 55;
            let percent = (sliderValue + 60) * 2.5 / 100
            r = r0 + percent * (238 - r0);
            g = g0 + percent * (185 - g0);
            b = b0 + percent * (74 - b0);

        } else if (sliderValue > -20 && sliderValue <= 20) {
            r0 = 238;
            g0 = 185;
            b0 = 74;
            let percent = (sliderValue + 20) * 2.5 / 100
            r = r0 + percent * (87 - r0);
            g = g0 + percent * (135 - g0);
            b = b0 + percent * (153 - b0);
        }
        else if (sliderValue > 20 && sliderValue <= 100) {
            r0 = 87;
            g0 = 135;
            b0 = 153;
            let percent = (sliderValue - 20) * 1.25 / 100
            r = r0 + percent * (80 - r0);
            g = g0 + percent * (159 - g0);
            b = b0 + percent * (91 - b0);
        }

        colorResult += ("rgb(");
        colorResult += (r);
        colorResult += (",");
        colorResult += (g);
        colorResult += (",");
        colorResult += (b);
        colorResult += (")");

        return colorResult;
    }
    useEffect(() => {
        if (!location?.state?.curInstance || !location?.state?.insertedId || !location?.state?.playerName || !location?.state?.gameId) {
            navigate('/');
        } else {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/getGameById?gameId=${location.state.gameId}`).then(
                res => {

                },
                err => {
                    navigate('/');
                }
            )


            if (firstFetch === 1) {
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/allShares/`, {
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                    }, params: { curInstance: location.state.curInstance }
                }).then(
                    res => {
                        let mydata = res.data
                        fetchedResult.current = mydata;
                        fetchedResult.current = fetchedResult.current.sort((a, b) => a.Time > b.Time ? 1 : -1)
                    }
                ).catch({
                })
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/tradeevents/`, {
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                    }, params: { curInstance: location.state.curInstance }
                }).then(
                    res => {
                        let mydata = res.data
                        fetchedEvents.current = mydata;
                        fetchedEvents.current = fetchedEvents.current.sort((a, b) => a.round > b.round ? 1 : -1)
                    }
                ).catch({
                })

                if (!tutorialPage0) {
                    setTutorialPage0(true);

                }
                setFirstFetch(0);
            }
        }

    }, [firstFetch, location, navigate])

    const backToFirstTutorial = () => {
        setTutorialPage0(true)
        setTutorialPage1(false)
    }
    const backToSecondTutorial = () => {
        setTutorialPage1(true)
        setTutorialPage2(false)
    }
    const backToThirdTutorial = () => {
        setTutorialPage2(true)
        setTutorialPage3(false)
    }


    const openSecondTutorial = () => {
        setTutorialPage1(true);
        setTutorialPage0(false);
    }


    const openThirdTutorial = () => {
        setTutorialPage2(true);
        setTutorialPage1(false);
    }

    const openLastTutorial = () => {
        setTutorialPage3(true);
        setTutorialPage2(false);
    }

    const loadingGame = () => {
        setIsHidden(false);
        setTutorialPage3(false);
        finishTutorial.current = true;
    }

    useEffect(() => {
        if (frozenBoolean === true) {
            //open the event modal,load event contents and set eventContent and eventResult
            if (fetchedEvents.current[0]) {
                console.log(fetchedEvents.current[0]);
                setEventContent(fetchedEvents.current[0].alert)
                setEventResult(fetchedEvents.current[0].outcome)
            } else {
                console.log(fetchedEvents.current);
            }
            fetchedEvents.current.shift();
        } else if (frozenBoolean === false) {
            // console.log("to event result modal")
        }
    }, [frozenBoolean])


    const routeChange = () => {
        let path = '/Thanks';
        navigate(path, { state: { insertedId: location.state.insertedId, playerName: location.state.playerName, score: profitsTrack[profitsTrack.length - 1].gain } });
    }

    useEffect(() => {
        if (frozen == 0 && curTime < gameLength) {
            setFrozenBoolean(false);
            launch.current = setInterval(() => ReadData(), 200)
        } else if (frozen == 1 && curTime < gameLength) {
            setFrozenBoolean(true);
            console.log("deleting");
            clearInterval(launch.current);
        } else if (frozen <= -1 && curTime >= gameLength) {
            console.log("game finished");
            routeChange();
            clearInterval(launch.current);
            curTime = 0;
            curRound = 0;
            tradeRecordLength = 0;
        }
    }, [frozen]);

    useEffect(() => {
        if (values.length > 250 && values.length < 350) {
            values.shift();
        } else if (values.length >= 350 && values.length < 450) {
            values.splice(0, 100);
        } else if (values.length >= 550) {
            values.splice(0, 200);
        }

        //play up tick sound or down tick sound
        if (values.length > 0) {
            //update oscillate flag first
            if (oscillateFlag.current == false) {
                if (Math.abs(parseInt(values[parseInt(values.length) - 1].stock) - parseInt(lastRing.current[0].stock)) >= 5) {
                    oscillateFlag.current = true;
                }
            }
            if (parseInt(values[parseInt(values.length) - 1].stock) % 10 == 0 || Math.abs(parseInt(values[parseInt(values.length) - 1].stock) - parseInt(lastRing.current[0].stock)) >= 10) {
                if (parseInt(values[parseInt(values.length) - 1].stock) > parseInt(lastRing.current[0].stock)) {
                    playUp();
                    lastRing.current.pop();
                    lastRing.current.push(values[values.length - 1]);
                    lastRingType.current = 0;
                    oscillateFlag.current = false;
                } else if (parseInt(values[parseInt(values.length) - 1].stock) < parseInt(lastRing.current[0].stock)) {
                    playDown();
                    lastRing.current.pop();
                    lastRing.current.push(values[values.length - 1]);
                    lastRingType.current = 1;
                    oscillateFlag.current = false;
                } else {
                    //consider oscillate and play sound accordingly
                    if (oscillateFlag.current) {
                        if (lastRingType.current == 0) {
                            playDown();
                            oscillateFlag.current = false;
                        } else if (lastRingType.current == 1) {
                            playUp();
                            oscillateFlag.current = false;
                        }
                    }
                }
            }
        }
    }, [values]);

    useEffect(() => {
        updateProfits();
    }, [position]);

    useEffect(() => {
        while (tempOrders.current.length != 0) {
            orders.push(tempOrders.current.pop());
        }
    }, [profitsHistory]);

    useEffect(() => {
        updateProfitsTrack();
    }, [values])

    useEffect(() => {
        if (profitsTrack.length > 300) {
            profitsTrack.shift();
        }
    }, [profitsTrack])

    const updateProfits = () => {
        if (orders.length != 0 && justThroughSecond.current == true) {
            if (tempOrders.current.length != 0 && position == 0) {
                justThroughSecond.current = false;
                let result = 0;
                let buySum = 0;
                let sellSum = 0;
                tempOrders.current.forEach(o => {
                    orders.push(o);
                })
                tempOrders.current = [];
                orders.forEach(o => {
                    if (o.order == "Buy") {
                        buySum += parseInt(o.orderAddress);
                    } else if (o.order == "Sell") {
                        sellSum += parseInt(o.orderAddress);
                    }
                })
                result = sellSum - buySum;
                if (profitsHistory.length == 0) {
                    setOrders([]);
                    setProfitsHistory({ "gain": result });
                } else {
                    setOrders([]);
                    setProfitsHistory({ "gain": profitsHistory.gain + result });
                }
            }
            else if (tempOrders.current.length != 0 && position != 0) {
                let result = 0;
                let buySum = 0;
                let sellSum = 0;
                let buyOrderCount = 0;
                let sellOrderCount = 0;
                let initBuyOrderCount = 0;
                let initSellOrderCount = 0;
                orders.forEach(o => {
                    if (o.order == "Buy") {
                        buyOrderCount++;
                        initBuyOrderCount++;
                    } else if (o.order == "Sell") {
                        sellOrderCount++;
                        initSellOrderCount++;
                    }
                })
                tempOrders.current.forEach(o => {
                    if (o.order == "Buy") {
                        buyOrderCount++;
                    } else if (o.order == "Sell") {
                        sellOrderCount++;
                    }
                })
                if (buyOrderCount != sellOrderCount) {
                    if (buyOrderCount > sellOrderCount) {
                        if (initBuyOrderCount < initSellOrderCount) {
                            //when pass over 0 after event
                            for (let i = 0; i < initSellOrderCount - initBuyOrderCount; i++) {
                                orders.push(tempOrders.current.pop());
                            }
                            orders.forEach(o => {
                                if (o.order == "Buy") {
                                    buySum += parseInt(o.orderAddress);
                                } else if (o.order == "Sell") {
                                    sellSum += parseInt(o.orderAddress);
                                }
                            })
                            result = sellSum - buySum;
                            if (profitsHistory.length == 0) {
                                setOrders([]);
                                setProfitsHistory({ "gain": result });
                            } else {
                                setOrders([]);
                                setProfitsHistory({ "gain": profitsHistory.gain + result });
                            }
                        } else {
                            if (sellOrderCount - initSellOrderCount > 0) {
                                for (let i = 0; i < sellOrderCount - initSellOrderCount; i++) {
                                    orders.push(tempOrders.current.pop());
                                }
                            } else if (buyOrderCount - initBuyOrderCount > 0) {
                                for (let i = 0; i < buyOrderCount - initBuyOrderCount; i++) {
                                    orders.push(tempOrders.current.pop());
                                }
                            }
                        }

                    } else {
                        if (initSellOrderCount < initBuyOrderCount) {
                            //when pass over 0 after event
                            for (let i = 0; i < initBuyOrderCount - initSellOrderCount; i++) {
                                orders.push(tempOrders.current.pop());
                            }

                            orders.forEach(o => {
                                if (o.order == "Buy") {
                                    buySum += parseInt(o.orderAddress);
                                } else if (o.order == "Sell") {
                                    sellSum += parseInt(o.orderAddress);
                                }
                            })
                            result = sellSum - buySum;
                            if (profitsHistory.length == 0) {
                                setOrders([]);
                                setProfitsHistory({ "gain": result });
                            } else {
                                setOrders([]);
                                setProfitsHistory({ "gain": profitsHistory.gain + result });
                            }
                        } else {
                            if (sellOrderCount - initSellOrderCount > 0) {
                                for (let i = 0; i < sellOrderCount - initSellOrderCount; i++) {
                                    orders.push(tempOrders.current.pop());
                                }
                            } else if (buyOrderCount - initBuyOrderCount > 0) {
                                for (let i = 0; i < buyOrderCount - initBuyOrderCount; i++) {
                                    orders.push(tempOrders.current.pop());
                                }
                            }
                        }
                    }
                }

                justThroughSecond.current = false;
            }
        }
        else if (orders.length == 0 && justThroughSecond.current == true) {
            while (tempOrders.current.length != 0) {
                orders.push(tempOrders.current.pop());
            }
        }
        else if (orders.length != 0 && justThroughSecond.current == false) {
            if (position == 0) {
                let result = 0;
                let buySum = 0;
                let sellSum = 0;
                orders.forEach(o => {
                    if (o.order == "Buy") {
                        buySum += parseInt(o.orderAddress);
                    } else if (o.order == "Sell") {
                        sellSum += parseInt(o.orderAddress);
                    }
                })
                result = sellSum - buySum;
                if (profitsHistory.length == 0) {
                    setOrders([]);
                    setProfitsHistory({ "gain": result });
                } else {
                    setOrders([]);
                    setProfitsHistory({ "gain": profitsHistory.gain + result });
                }
            }
        }
    }

    const updateProfitsTrack = () => {
        let potentialGain = 0;
        //calculate potential gain
        let buyCount = 0;
        let sellCount = 0;
        let buySum = 0;
        let sellSum = 0;
        let lastBuy = 0;
        let lastSell = 0;
        orders.forEach(o => {
            if (o.order == "Buy") {
                buyCount += 1;
                buySum += parseInt(o.orderAddress);
                lastBuy = o.orderAddress;
            } else if (o.order == "Sell") {
                sellCount += 1;
                sellSum += parseInt(o.orderAddress);
                lastSell = o.orderAddress;
            }
        })

        if (buyCount > sellCount) {
            potentialGain = Math.round(sellSum - buySum + values[values.length - 1].stock * (buyCount - sellCount));
        } else if (sellCount > buyCount) {
            potentialGain = Math.round(sellSum - buySum - values[values.length - 1].stock * (sellCount - buyCount));
        }

        if (profitsHistory.length == 0) {
            if (profitsTrack.length > 300) {
                profitsTrack.shift();
            }
            setProfitsTrack(profitsTrack.concat({ "gain": potentialGain }));
            profitsYAxisLowerBound.current = -15 + potentialGain;
            profitsYAxisUpperBound.current = 15 + potentialGain;
        } else {
            if (profitsTrack.length > 300) {
                profitsTrack.shift();
            }
            profitsYAxisLowerBound.current = parseInt(profitsHistory.gain + potentialGain) - 15;
            profitsYAxisUpperBound.current = parseInt(profitsHistory.gain + potentialGain) + 15;
            setProfitsTrack(profitsTrack.concat({ "gain": parseInt(profitsHistory.gain) + potentialGain }));
        }
    }

    const ReadData = () => {
        // console.log(firstFetch)
        // console.log(initTrade.current)
        // console.log(fetchedResult.current);
        // console.log(curTime);
        if (curTime < gameLength) {
            if (initTrade.current == true) {
                if (curTime < 25 && curRound == 0 && eventFinished == 0) {
                    //load first 40 seconds data
                    if (fetchedResult.current == undefined && finishTutorial.current == false) {

                    } else if (fetchedResult.current != undefined && finishTutorial.current == true) {
                        dialvalue0.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial1);
                        signal0Color.current = fixingColor(dialvalue0.current);
                        dialvalue1.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial2);
                        signal1Color.current = fixingColor(dialvalue1.current);
                        dialvalue2.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial3);
                        signal2Color.current = fixingColor(dialvalue2.current);
                        dialvalue3.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial4);
                        dialvalue4.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial5);
                        setValues(values =>
                            values.concat({
                                "time": fetchedResult.current[parseInt(curTime * 50)].Time, "stock": fetchedResult.current[parseInt(curTime * 50)].Stock
                            }))
                        yAxisLowerBound.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Stock) - 15;
                        yAxisUpperBound.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Stock) + 15;

                        curTime = parseFloat(curTime) + 0.20;
                        curTime = curTime.toFixed(2);
                    }
                }
                else {
                    initTrade.current = false;
                    setFrozen(1);
                }
            } else {
                if (curTime < curRound * 25 + curRound * 0.5 + 25 && eventFinished === 0) {
                    if (fetchedResult.current === undefined) {

                    } else {
                        dialvalue0.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial1);
                        signal0Color.current = fixingColor(dialvalue0.current);
                        dialvalue1.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial2);
                        signal1Color.current = fixingColor(dialvalue1.current);
                        dialvalue2.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial3);
                        signal2Color.current = fixingColor(dialvalue2.current);
                        dialvalue3.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial4);
                        dialvalue4.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Dial5);
                        setValues(values =>
                            values.concat({
                                "time": fetchedResult.current[parseInt(curTime * 50)].Time, "stock": fetchedResult.current[parseInt(curTime * 50)].Stock
                            }))
                        yAxisLowerBound.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Stock) - 15;
                        yAxisUpperBound.current = parseInt(fetchedResult.current[parseInt(curTime * 50)].Stock) + 15;

                        curTime = parseFloat(curTime) + 0.20;
                        curTime = curTime.toFixed(2);
                    }

                }
                else {
                    //whatever doing, freeze the render first!
                    setFrozen(1);
                    if (eventFinished === 1) {
                        console.log("event has been done and now maybe it's loading 0.5 seconds data");
                    } else {
                        console.log("error detected while reading data");
                    }
                }
            }
        }
        else {
            //means we have reached the end of game
            setFrozen(frozen - 1);
            // routeChange();
            // curTime = 0;
            // curRound = 0;
            // tradeRecordLength = 0;
            console.log("trying to read data that is beyond the limit of game length");
        }
    }

    const incrementCurRound = () => {
        //set time limits for triggering read half second data
        eventFinished = 1;
        //also reset the event confirm clicked button
        eventConfirmClicked.current = 0;

        if (curTime < gameLength) {
            curRound += 1;
            positionValueFromEvent.current = 0;
            orderPlaced.current = false;
        } else {
            console.log("we are reaching the end of the game");
        }
        setFrozen(0);
    }

    const addRecord = (theRecord) => {
        if (records.length == 4) {
            records.shift();
            records.push(theRecord);
            setRecords(records);
        } else if (records.length >= 0 && records.length < 4) {
            records.push(theRecord);
            setRecords(records);
        } else {
            console.log("error occurs when placing orders")
        }
    }

    const showRecord = () => {
        if (tradeRecordLength < 4) {
            tradeRecordLength += 1;
            if (recordsForDisplay.length > 0) {
                let item = recordsForDisplay.pop();
                if (item.order == "Buy") {
                    return (
                        <Grid.Row >
                            <Recordpartbuy >
                                < Grid style={{ marginTop: 3, marginBottom: 3 }}>
                                    <GridColumn width={2} style={{ fontWeight: 900, fontSize: '14px', fontFamily: "'Noto Sans JP', sans-serif", marginLeft: 28, color: '#1C3255' }}>
                                        BUY
                                    </GridColumn>
                                    <GridColumn style={{ fontWeight: 900, fontSize: '14x', fontFamily: "'Noto Sans JP', sans-serif", marginLeft: -14, color: '#1C3255' }}>
                                        AT
                                    </GridColumn>
                                    <GridColumn floated='right' style={{ fontWeight: 900, fontSize: '14px', fontFamily: 'Roboto', marginRight: 40, color: '#1C3255' }}>
                                        {item.orderAddress}
                                    </GridColumn>
                                </Grid >
                            </Recordpartbuy>
                        </Grid.Row>
                    )
                } else if (item.order == "Sell") {
                    return (
                        <Grid.Row >
                            <Recordpartsell >
                                < Grid style={{ marginTop: 3, marginBottom: 3 }}>
                                    <GridColumn width={2} style={{ fontWeight: 900, fontSize: '14px', fontFamily: "'Noto Sans JP', sans-serif", marginLeft: 28, color: '#ff3300' }}>
                                        SELL
                                    </GridColumn>
                                    <GridColumn style={{ fontWeight: 900, fontSize: '14px', fontFamily: "'Noto Sans JP', sans-serif", marginLeft: -14, color: '#ff3300' }}>
                                        AT
                                    </GridColumn>
                                    <GridColumn floated='right' style={{ fontWeight: 900, fontSize: '14px', fontFamily: 'Roboto', marginRight: 40, color: '#ff3300' }}>
                                        {item.orderAddress}
                                    </GridColumn>
                                </Grid >
                            </Recordpartsell>
                        </Grid.Row>
                    )
                }
            }
        }
    }

    const resetTradeRecordLength = () => {
        tradeRecordLength = 0;
    }

    const placeBuy = () => {
        if (position < 5 && fetchedResult.current != undefined) {
            justThroughSecond.current = false;
            record = { order: "Buy", orderAddress: parseInt(values[values.length - 1].stock) };
            addRecord(record);
            orders.push(record);
            setPosition(position + 1);
            playBuy();
        }
    }
    const placeSell = () => {
        if (position > -5 && fetchedResult.current != undefined) {
            justThroughSecond.current = false;
            record = { order: "Sell", orderAddress: parseInt(values[values.length - 1].stock) };
            addRecord(record)
            orders.push(record);
            setPosition(position - 1);
            playSell();
        }
    }

    const savedSecondCallback = useRef();

    function secondCallback() {
        if (secondTimePassed > 0 && secondOpen == true) {
            setSecondTimePassed(secondTimePassed - 1);
        } else if (secondTimePassed <= 0 && secondOpen == true) {
            setSecondTimePassed(5);
            setSecondOpen(false);
            if (curRound == 2 || curRound == 3 || curRound == 4) {
                //for last 3 events
                setTimePassed(45);
            } else {
                //normal events waiting time
                setTimePassed(30);
            }
            incrementCurRound();
        }
    }

    const id2 = useRef();
    useEffect(() => {
        savedSecondCallback.current = secondCallback;
    });

    useEffect(() => {
        function tick() {
            savedSecondCallback.current();
        }
        if (frozen == 1) {
            id2.current = setInterval(tick, 1000);
        } else {
            clearInterval(id2.current);
        }
    }, [firstModalDone, frozen]);

    const savedCallback = useRef();

    function callback() {
        if (timePassed > 0 && secondOpen == false) {
            setTimePassed(timePassed - 1);
        } else if (timePassed <= 0 && secondOpen == false) {
            setFrozenBoolean(false);
            setSecondOpen(true);
            setFirstModalDone(1);
        }
        //otherwise if second window is open we do nothing on first window
    }

    const id = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (frozen == 1) {
            id.current = setInterval(tick, 1000);
        } else {
            clearInterval(id.current);
        }
    }, [frozen]);

    const showPosition = () => {
        if (position >= 0) {
            return (
                <Numberinpositionbox style={{ fontSize: 22 }}>
                    {'+' + position}
                </Numberinpositionbox>
            )
        }
        return (
            <Numberinpositionbox style={{ fontSize: 22 }}>
                {position}
            </Numberinpositionbox>
        )
    }

    const showWaiting = () => {
        console.log("we will need to wait before it showing the response of event");
        //change button style to seconds that needs to be waited
        //store information that user decided to make
        eventConfirmClicked.current = 1;
        if (orderPlaced.current == false) {
            if (position < parseInt(positionValueFromEvent.current)) {
                for (let i = position; i < parseInt(positionValueFromEvent.current); i++) {
                    if (i < 5) {
                        record = { order: "Buy", orderAddress: parseInt(values[values.length - 1].stock) };
                        addRecord(record)
                        tempOrders.current.push(record);
                    }
                }
                justThroughSecond.current = true;
                setPosition(parseInt(positionValueFromEvent.current));

            } else if (position > parseInt(positionValueFromEvent.current)) {
                for (let i = position; i > parseInt(positionValueFromEvent.current); i--) {
                    if (i > -5) {
                        record = { order: "Sell", orderAddress: parseInt(values[values.length - 1].stock) };
                        addRecord(record)
                        tempOrders.current.push(record);
                    }
                }
                justThroughSecond.current = true;
                setPosition(parseInt(positionValueFromEvent.current));
            }
            orderPlaced.current = true;
        }
    }

    const changePositionsFromModal = e => {
        if (e.target.textContent[0] == '-') {
            positionValueFromEvent.current = (Number(e.target.textContent[0] + e.target.textContent[1]));
        } else {
            positionValueFromEvent.current = (Number(e.target.textContent[0]));
        }
    }

    const orderPlaced = useRef(false);
    const showButtonLoading = () => {
        if (frozenBoolean) {
            if (eventConfirmClicked.current == 1) {
                //if clicked submit, load the loading button for user
                return (
                    <Button
                        align='right'
                        size='massive'
                        // size='massive'
                        disabled={true}
                        style={{
                            borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                            , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300,

                        }}
                    // style={{ backgroundColor: "#ff3300", color: "#ffffff", borderRadius: 60 }}
                    >
                        <eventbuttontext >
                            Trading starting in {timePassed} seconds
                        </eventbuttontext>
                    </Button>
                )

            } else {
                //return default submit button
                return (
                    <Button
                        align='right'
                        size='massive'
                        // fluid

                        onClick={() =>
                            showWaiting()
                        }
                        style={{
                            borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                            , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300,

                        }}
                    // style={{ backgroundColor: "#ff3300", color: "#ffffff", borderRadius: 60 }}
                    >
                        <Ptraspaninvisibleintegertext>
                            sss
                        </Ptraspaninvisibleintegertext>
                        <eventbuttontext >
                            Submit
                        </eventbuttontext>
                        <Ptraspaninvisibleintegertext>
                            sss
                        </Ptraspaninvisibleintegertext>
                    </Button>

                )
            }
        }
    }

    const showButtonInSecondWaiting = () => {
        return (
            <Button
                size='massive'
                disabled={true}
                icon='check'
                style={{
                    borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                    , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300,

                }}
            // style={{ backgroundColor: "#ff3300", color: "#ffffff", borderRadius: '1em' }}
            >
                <eventbuttontext>
                    Trading starting in {secondTimePassed} seconds
                </eventbuttontext>
            </Button>
        )
    }

    return (


        <Container style={{
            paddingTop: 0.05 * window.innerHeight, paddingBottom: 0.1 * window.innerHeight,
            paddingLeft: 0.01 * window.innerWidth, paddingRight: 0.01 * window.innerWidth,
        }}>
            <style>
                {`
                html,body{
                    background-color: rgb(235,245,253);
                }
            `}
            </style>

            <Grid columns='equal' style={{ display: isHidden ? 'none' : 'block' }}>
                <Segment className="segmentStyle" style={{
                    borderRadius: "2em",
                    borderColor: 'white',
                    borderWidth: '1px',
                    boxShadow: '2px 1px 50px 3px #cccccc '
                }}>
                    <Grid.Row columns='equal'>
                        <Grid.Column >
                            <Grid style={{
                                width: '102.9%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 14 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <div style={{
                                                fontFamily: 'Noto Sans JP,sans-serif',
                                                fontWeight: 950,
                                                letterSpacing: '-.5px',
                                                fontSize: 17,
                                                paddingLeft: 30,
                                                textAlign: 'left'
                                            }}>Optiver Trading Game</div>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <Bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </Bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />


                            <Grid>
                                <Grid.Row style={{
                                    justifyContent: 'center', align: 'center', color: '#1c3255',
                                    fontWeight: 900, fontSize: '17px', fontFamily: "'Noto Sans JP', sans-serif"
                                }}>
                                    Move with the market to maximise P/L
                                </Grid.Row>
                            </Grid>

                            <Ptra>
                                <Grid relaxed>
                                    <Grid.Row>
                                    </Grid.Row>
                                    <Grid.Row columns={2} >
                                        <Grid.Column width={8}>
                                            <Ptra2 >
                                                <Grid columns='equal'>
                                                    <Grid.Column style={{ marginTop: 0 }}>
                                                        <span className="notosansjpblack" style={{
                                                            marginLeft: '30px'
                                                            , fontSize: '16px', color: '#1c3255'
                                                        }}>
                                                            Stock Viewer
                                                        </span>
                                                    </Grid.Column>

                                                    <Grid.Column align='right'>
                                                        <Button color='black' basic
                                                            style={{
                                                                fontSize: "16px", width: '31%', fontWeight: 900,
                                                                marginRight: -14, borderRadius: '0.5em'
                                                            }}>
                                                            <Numberinbox style={{ inlineTextAlign: 'center' }} >
                                                                ${parseInt(values[values.length - 1].stock)}
                                                            </Numberinbox>
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid>
                                                <Segment style={{
                                                    width: '97%', marginLeft: 30, border: "1px solid black",
                                                    borderTopWidth: '0px', borderBottomLeftRadius: '14px', borderBottomRightRadius: '14px',
                                                    borderTopLeftRadius: '0px', borderTopRightRadius: '0px'
                                                }}>
                                                    <AreaChart width={505} height={180} data={values}
                                                        margin={{ top: 10, right: 0, left: 0, bottom: 10 }} >
                                                        <defs>
                                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">

                                                                <stop offset="20%" stopColor="#82C4BD" stopOpacity={0.8} />
                                                                <stop offset="80%" stopColor="#82C4BD" stopOpacity={0.2} />
                                                            </linearGradient>
                                                        </defs>
                                                        <YAxis orientation='right' tickCount='7'
                                                            allowDecimals='false' allowDataOverflow='true'
                                                            axisLine='false' tickLine='false'
                                                            domain={[Math.round(yAxisLowerBound.current), Math.round(yAxisUpperBound.current)]}
                                                            tick={{
                                                                fontSize: 12, fontFamily: "'Roboto', sans-serif",
                                                                fontWeight: 950, fill: '#1C3255', letterSpacing: '-0.5px'
                                                            }}

                                                        />
                                                        <CartesianGrid strokeDasharray="0" vertical={false} />
                                                        <Area type="monotone" dataKey="stock" stroke="#82C4BD" fillOpacity={0.6} fill="url(#colorUv)" />
                                                    </AreaChart>
                                                </Segment>
                                            </Ptra2>

                                        </Grid.Column>
                                        <Grid.Column width={8} style={{ paddingRight: 24 }}>
                                            <Grid >
                                                <Grid.Row style={{
                                                    marginLeft: 80, justifyContent: 'left', align: 'center', fontWeight: 900
                                                    , fontSize: '16px', fontFamily: "'Noto Sans JP', sans-serif", color: '#1c3255', marginTop: 14
                                                }}>
                                                    Trades
                                                </Grid.Row>
                                            </Grid>
                                            <Ptra2 style={{ marginTop: 4 }}>
                                                <Grid relaxed>
                                                    <Grid.Row >
                                                        <GridColumn width={2}>

                                                        </GridColumn>
                                                        <GridColumn width={13} >
                                                            {
                                                                showRecord()
                                                            }
                                                            {
                                                                showRecord()
                                                            }
                                                            {
                                                                showRecord()
                                                            }
                                                            {
                                                                showRecord()
                                                            }
                                                            {resetTradeRecordLength()}
                                                        </GridColumn>
                                                        <GridColumn width={1}>
                                                        </GridColumn>
                                                    </Grid.Row>

                                                </Grid>
                                            </Ptra2>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ marginTop: -20, marginBottom: -20 }}>

                                        <Grid.Column width={8}>
                                            <Ptra3 >
                                                <Grid >
                                                    <Grid.Row style={{ fontFamily: "'Noto Sans JP', sans-serif", color: '#1c3255', marginLeft: '40px', fontWeight: 900, fontSize: '16px' }}>
                                                        Stock Signals
                                                    </Grid.Row>
                                                </Grid>

                                                <Grid divided='vertically' style={{ marginLeft: -30 }}>
                                                    <Grid.Row columns={7} >
                                                        <GridColumn>
                                                        </GridColumn>
                                                        <GridColumn>
                                                            <Box sx={{ height: 220 }}>
                                                                <Slider
                                                                    sx={{
                                                                        '& input[type="range"]': {
                                                                            WebkitAppearance: 'slider-vertical',
                                                                        },
                                                                        width: 30,
                                                                        color: signal0Color.current,
                                                                        '& .MuiSlider-thumb': {
                                                                            borderRadius: '1px',
                                                                            width: '40px',
                                                                            height: '30px',
                                                                            opacity: 0,
                                                                        },
                                                                        '& .MuiSlider-rail': {
                                                                            borderRadius: '37px',
                                                                            color: '#BCC2CC',
                                                                        },
                                                                        borderTopLeftRadius: '0px',
                                                                        borderTopRightRadius: '0px',
                                                                    }}

                                                                    orientation="vertical"
                                                                    value={dialvalue0.current}
                                                                    aria-label="Dial1"
                                                                    valueLabelDisplay="auto"
                                                                    max={100}
                                                                    min={-100}
                                                                />
                                                            </Box>
                                                        </GridColumn>
                                                        <GridColumn>
                                                            <Box sx={{ height: 220 }}>
                                                                <Slider
                                                                    sx={{
                                                                        '& input[type="range"]': {
                                                                            WebkitAppearance: 'slider-vertical',
                                                                        },
                                                                        width: 30,
                                                                        color: signal1Color.current,
                                                                        '& .MuiSlider-thumb': {
                                                                            borderRadius: '7px',
                                                                            width: '40px',
                                                                            height: '30px',
                                                                            opacity: 0,
                                                                        },
                                                                        '& .MuiSlider-rail': {
                                                                            borderRadius: '37px',
                                                                            color: '#BCC2CC',
                                                                        },
                                                                        borderTopLeftRadius: '0px',
                                                                        borderTopRightRadius: '0px',
                                                                    }}
                                                                    orientation="vertical"
                                                                    value={dialvalue1.current}
                                                                    aria-label="Dial2"
                                                                    valueLabelDisplay="auto"
                                                                    max={100}
                                                                    min={-100}
                                                                />
                                                            </Box>
                                                        </GridColumn>
                                                        <GridColumn>
                                                            <Box sx={{ height: 220 }}>
                                                                <Slider
                                                                    sx={{
                                                                        '& input[type="range"]': {
                                                                            WebkitAppearance: 'slider-vertical',
                                                                        },
                                                                        width: 30,
                                                                        color: signal2Color.current,
                                                                        '& .MuiSlider-thumb': {
                                                                            borderRadius: '7px',
                                                                            width: '40px',
                                                                            height: '30px',
                                                                            opacity: 0,
                                                                        },
                                                                        '& .MuiSlider-rail': {
                                                                            borderRadius: '37px',
                                                                            color: '#BCC2CC',
                                                                        },
                                                                        borderTopLeftRadius: '0px',
                                                                        borderTopRightRadius: '0px',
                                                                    }}
                                                                    orientation="vertical"
                                                                    value={dialvalue2.current}
                                                                    aria-label="Dial3"
                                                                    valueLabelDisplay="auto"
                                                                    max={100}
                                                                    min={-100}
                                                                />
                                                            </Box>
                                                        </GridColumn>
                                                        <GridColumn >
                                                            {Gauge0}
                                                            {Gauge1}
                                                        </GridColumn>
                                                    </Grid.Row>
                                                </Grid>
                                            </Ptra3>
                                        </Grid.Column>
                                        <Grid.Column width={8} style={{ paddingRight: 28 }}>
                                            <Ptra3 style={{ marginRight: '1em' }}>
                                                <Grid columns='equal'>
                                                    <Grid.Column style={{
                                                        textAlign: 'center', marginLeft: '55px', fontSize: '14px',
                                                        color: '#1c3255', fontFamily: "'Noto Sans JP', sans-serif", marginTop: 7
                                                    }}>
                                                        <Notosansjpblack style={{ marginLeft: -10, fontSize: 16 }}>
                                                            Profit/Loss
                                                        </Notosansjpblack>
                                                    </Grid.Column>
                                                    <Grid.Column width={6} >
                                                    </Grid.Column>
                                                    <Grid.Column justifyContent='right' align='right'>
                                                        <Button color='black' basic
                                                            style={{
                                                                fontSize: "16px", width: '70%', fontWeight: 900,
                                                                marginLeft: -30, marginRight: 14, borderRadius: '0.5em', textAlign: 'center'
                                                            }}>
                                                            <Numberinbox style={{ inlineTextAlign: 'center' }} >
                                                                ${profitsTrack[profitsTrack.length - 1].gain}
                                                            </Numberinbox>
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid>
                                                <Segment style={{
                                                    width: '84%', marginLeft: 70, border: "1px solid black",
                                                    borderTopWidth: '0px', borderBottomLeftRadius: '14px', borderBottomRightRadius: '14px',
                                                    borderTopLeftRadius: '0px', borderTopRightRadius: '0px'
                                                }}>
                                                    <AreaChart width={420} height={180} data={profitsTrack}
                                                        margin={{ top: 10, right: 0, left: 0, bottom: 10 }}>
                                                        <defs>
                                                            <linearGradient id="colorProfits" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="20%" stopColor="#514F9B" stopOpacity={0.8} />
                                                                <stop offset="80%" stopColor="#514F9B" stopOpacity={0.2} />
                                                            </linearGradient>
                                                        </defs>

                                                        <YAxis orientation='right' tickCount='7'
                                                            allowDecimals={false} allowDataOverflow='true'
                                                            axisLine='false' tickLine='false'
                                                            type="number" domain={[Math.round(profitsYAxisLowerBound.current), Math.round(profitsYAxisUpperBound.current)]}
                                                            tick={{
                                                                fontSize: 12, fontFamily: "'Roboto', sans-serif",
                                                                fontWeight: 950, fill: '#1C3255', letterSpacing: '-0.5px'
                                                            }}
                                                        />
                                                        <CartesianGrid strokeDasharray="0" vertical={false} />

                                                        <Area type="monotone" dataKey="gain" stroke="#514F9B" fill="url(#colorProfits)" />
                                                    </AreaChart>
                                                </Segment>
                                                {/* {profits} */}
                                            </Ptra3>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid relaxed>
                                    <Grid.Row>

                                        <Grid.Column width={4}>
                                            <Button fluid size='massive' onClick={placeBuy} style={{
                                                marginTop: '0.5em', marginLeft: '1.5em'
                                                , borderRadius: '2em', backgroundColor: '#1C3255', color: '#fff'
                                                , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 26, fontWeight: 900
                                            }}>BUY</Button>
                                        </Grid.Column>

                                        <Grid.Column width={4}>
                                            <Button fluid size='massive' onClick={placeSell} style={{
                                                marginTop: '0.5em', marginLeft: '1em'
                                                , borderRadius: '2em', backgroundColor: '#FF3300', color: '#fff'
                                                , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 26, fontWeight: 900
                                            }}>SELL</Button>
                                        </Grid.Column>

                                        <Grid.Column width={8}>
                                            <Ptra5 style={{ marginRight: '1em', marginTop: '-0.25em' }}>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <GridRow>
                                                            </GridRow>
                                                            <Grid>
                                                                <GridRow>
                                                                    <Notosansjpblack style={{ marginLeft: 200, paddingTop: 40, fontSize: 22, color: '#1c3255', fontWeight: 950 }}>Position</Notosansjpblack>
                                                                </GridRow>
                                                            </Grid>
                                                        </Grid.Column>
                                                        <Grid.Column width={8} >
                                                            <Grid divided='vertically'>
                                                                <GridRow columns={2}>
                                                                    <Grid.Column>
                                                                        <Button color='black' basic size='medium'
                                                                            style={{ height: '70%', width: '70%', borderRadius: '0.5em', marginTop: 28, marginLeft: 40, fontWeight: 950, paddingBottom: -40, marginBottom: 0 }}>
                                                                            {
                                                                                showPosition()
                                                                            }
                                                                        </Button>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </GridRow>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Ptra5>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                    </Grid.Row>
                                </Grid>
                            </Ptra>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
            </Grid>

            <Modal open={tutorialPage0} size='large' basic >
                <Modal.Content >
                    <Grid columns='equal'>
                        <Segment className="segmentStyle" style={{
                            borderRadius: "2em",
                            borderColor: 'white',
                            borderWidth: '1px',
                            // boxShadow: '2px 4px 12px 3px #808080  ',
                            width: '100%'
                        }}>
                            <Grid style={{
                                width: '103%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 5 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <div style={{
                                                fontFamily: 'Noto Sans JP,sans-serif',
                                                fontWeight: 950,
                                                letterSpacing: '-.5px',
                                                fontSize: 17,
                                                paddingLeft: 30,
                                                textAlign: 'left'
                                            }}>Optiver Trading Game</div>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <Bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </Bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />

                            <Grid.Row style={{ marginTop: 60 }}>
                                <Grid.Column >
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={6} style={{ marginLeft: '-10px' }}>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Spp1>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        A trader needs to make good trading decisions.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        They have an entire universe of information available to them and a lot of work goes into
                                                        cleaning, analysing and summarising this into clean trading signals.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        In the game you are about to play, this has already been done for you, and so you will be
                                                        presented with information already in the form of simple sliders and gauges.
                                                    </span>
                                                </Spp1>
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                <img src={sc0} style={{
                                                    borderRadius: "2em",
                                                    borderColor: 'black',
                                                    borderWidth: '1px',
                                                    boxShadow: '2px 4px 12px 3px #cccccc  ',
                                                    width: '95%',
                                                    marginLeft: '14px'
                                                }}>

                                                </img>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns='equal' style={{ marginTop: 30, marginBottom: 30 }}>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row columns={5}>
                                            <Grid.Column>

                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>

                                                <Button fluid onClick={openSecondTutorial} style={{
                                                    borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                                                    , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300
                                                }}>Next
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Segment>
                    </Grid>
                </Modal.Content>
            </Modal>

            <Modal open={tutorialPage1} size='large' basic >
                <Modal.Content >
                    <Grid columns='equal'>
                        <Segment className="segmentStyle" style={{
                            borderRadius: "2em",
                            borderColor: 'white',
                            borderWidth: '1px',
                            // boxShadow: '2px 4px 12px 3px #808080  ',
                            width: '100%'
                        }}>
                            <Grid style={{
                                width: '103%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 5 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <div style={{
                                                fontFamily: 'Noto Sans JP,sans-serif',
                                                fontWeight: 950,
                                                letterSpacing: '-.5px',
                                                fontSize: 17,
                                                paddingLeft: 30,
                                                textAlign: 'left'
                                            }}>Optiver Trading Game</div>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <Bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </Bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />
                            <Grid.Row style={{ marginTop: 60 }} >
                                <Grid.Column >
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={6} style={{ marginLeft: '-10px' }}>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Spp1>

                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        As you will be provided with these signals, your job is simple:
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        Make trades on a stock to maximise your profit.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        The gameplay is easy. Click buy to buy. Click sell to sell.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        You can buy or sell as frequently as you like, but you are only allowed to own at most 5 lots
                                                        of the stock. Likewise, you are limited to being short 5 lots.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        Therefore, your position at any time will be an integer from -5 to +5.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        A positive position profits when the stock goes up.
                                                    </span>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        A negative position profits when the stock goes down.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 13, color: '#1c3255' }}>
                                                        The bigger your position, the more your profits/losses will be magnified.
                                                    </span>
                                                </Spp1>
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                <img src={sc0}
                                                    style={{
                                                        borderRadius: "2em",
                                                        borderColor: 'black',
                                                        borderWidth: '1px',
                                                        boxShadow: '2px 4px 12px 3px #cccccc  ',
                                                        width: '95%',
                                                        marginLeft: '14px'
                                                    }}>
                                                </img>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns='equal' style={{ marginTop: 30, marginBottom: 30 }}>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row columns={5}>
                                            <Grid.Column>
                                                <a onClick={backToFirstTutorial} style={{ marginLeft: 20 }}>
                                                    <Icon name='arrow left' size='large' style={{ backgroundColor: '#ff3300', borderRadius: '2em', width: '2em', height: '2em', paddingTop: 10, color: 'white' }} />
                                                </a>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button fluid onClick={openThirdTutorial} style={{
                                                    borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                                                    , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300
                                                }}>Next
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Segment>
                    </Grid>
                </Modal.Content>
            </Modal>

            <Modal open={tutorialPage2} size='large' basic >
                <Modal.Content >
                    <Grid columns='equal'>
                        <Segment className="segmentStyle" style={{
                            borderRadius: "2em",
                            borderColor: 'white',
                            borderWidth: '1px',
                            // boxShadow: '2px 4px 12px 3px #808080  ',
                            width: '100%'
                        }}>
                            <Grid style={{
                                width: '103%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 5 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <div style={{
                                                fontFamily: 'Noto Sans JP,sans-serif',
                                                fontWeight: 950,
                                                letterSpacing: '-.5px',
                                                fontSize: 17,
                                                paddingLeft: 30,
                                                textAlign: 'left'
                                            }}>Optiver Trading Game</div>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <Bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </Bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />
                            <Grid.Row style={{ marginTop: 60 }}>
                                <Grid.Column >
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={6} style={{ marginLeft: '-10px' }}>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Ptraspaninvisibleintegertext>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                </Grid.Row>
                                                <Spp1>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        At certain times, you will be presented with News Alerts. These contain information about
                                                        an upcoming event.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        The market will freeze, and you will be given between 30-45 seconds to both read the event
                                                        and choose a position to carry into the event.
                                                    </span>
                                                    <Ptraspaninvisibleintegertext style={{ marginTop: -10 }}>
                                                        sss
                                                    </Ptraspaninvisibleintegertext>
                                                    <span className="notosansjpblack" style={{ fontSize: 14, color: '#1c3255' }}>
                                                        When this time is up, you will be informed of the outcome, and then after this the market
                                                        will resume.
                                                    </span>
                                                </Spp1>
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                <img src={sc1} style={{
                                                    borderRadius: "2em",
                                                    borderColor: 'black',
                                                    borderWidth: '1px',
                                                    boxShadow: '2px 4px 12px 3px #cccccc  ',
                                                    width: '95%',
                                                    marginLeft: '14px'
                                                }}>
                                                </img>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns='equal' style={{ marginTop: 30, marginBottom: 30 }}>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row columns={5}>
                                            <Grid.Column>
                                                <a onClick={backToSecondTutorial} style={{ marginLeft: 20 }}>
                                                    <Icon name='arrow left' size='large' style={{ backgroundColor: '#ff3300', borderRadius: '2em', width: '2em', height: '2em', paddingTop: 10, color: 'white' }} />
                                                </a>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button fluid onClick={openLastTutorial} style={{
                                                    borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                                                    , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300
                                                }}>Next
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Segment>
                    </Grid>
                </Modal.Content>
            </Modal>

            <Modal open={tutorialPage3} size='large' basic >
                <Modal.Content >
                    <Grid columns='equal'>
                        <Segment className="segmentStyle" style={{
                            borderRadius: "2em",
                            borderColor: 'white',
                            borderWidth: '1px',
                            // boxShadow: '2px 4px 12px 3px #808080  ',
                            width: '100%'
                        }}>
                            <Grid style={{
                                width: '103%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                                borderTopRightRadius: "2em"
                            }} >
                                <Grid.Row columns={2} style={{ marginTop: 5 }} >
                                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                                        <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                                            <div style={{
                                                fontFamily: 'Noto Sans JP,sans-serif',
                                                fontWeight: 950,
                                                letterSpacing: '-.5px',
                                                fontSize: 17,
                                                paddingLeft: 30,
                                                textAlign: 'left'
                                            }}>Optiver Trading Game</div>
                                        </Thp1>
                                    </Grid.Column>
                                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                                        <Bluebackgroundoptiverlogo>
                                            <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                                            </img>
                                        </Bluebackgroundoptiverlogo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider style={{ marginLeft: -14, width: '102.55%' }} />
                            <Grid.Row style={{ marginTop: 50 }}>
                                <Grid.Column >
                                    <Grid>
                                        <Grid.Row >

                                            <Grid.Column >
                                                <Lasttutorial style={{ textAlign: 'center' }}>
                                                    <Notosansjpblack style={{ fontSize: 24, color: '#1c3255' }}>
                                                        Can you beat an Optiver trader at trading?
                                                    </Notosansjpblack>

                                                    <Notosansjpblack className="notosansjpblack" style={{ fontSize: 24, color: '#1c3255' }}>
                                                        Click the start button to give it a go.
                                                    </Notosansjpblack>
                                                </Lasttutorial>
                                                <Grid style={{ marginTop: 0, marginBottom: 30 }}>
                                                    <Grid.Row columns={5}>
                                                        <Grid.Column style={{ marginTop: 65 }}>
                                                            <a onClick={backToThirdTutorial} style={{ marginLeft: 20 }}>
                                                                <Icon name='arrow left' size='large' style={{ backgroundColor: '#ff3300', borderRadius: '2em', width: '2em', height: '2em', paddingTop: 10, color: 'white' }} />
                                                            </a>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                        </Grid.Column>
                                                        <Grid.Column>

                                                            <Button fluid onClick={loadingGame} style={{
                                                                marginBottom: 200, marginTop: -130,
                                                                borderRadius: '2em', backgroundColor: '#ff3300', color: '#ffffff'
                                                                , fontFamily: "'Noto Sans JP', sans-serif", fontSize: 18, fontWeight: 300
                                                            }}>Start
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns='equal'>
                                <Grid.Column>

                                </Grid.Column>
                            </Grid.Row>
                        </Segment>
                    </Grid>
                </Modal.Content>
            </Modal>

            <Modal open={frozenBoolean} >
                {/* <Modal.Header textAlign='center'>News Alert</Modal.Header> */}

                <Modal.Content style={{ backgroundColor: "#ffffff" }}>
                    <Grid >
                        <Grid.Row columns={3} >
                            <Grid.Column >

                            </Grid.Column>
                            <Grid.Column>
                                <Container textAlign='center' >
                                    <ptraspaneventtitle className="notosansjpblack" >
                                        NEWS ALERT
                                    </ptraspaneventtitle>
                                </Container>
                            </Grid.Column>
                            <Grid.Column floated='right' width={2} style={{ marginRight: '50px' }} >
                                <Container textAlign='center'>
                                    <ptraptimer style={{ fontWeight: 900, borderRadius: 10 }}>
                                        00:
                                        {timePassed >= 10 ? timePassed : "0" + timePassed}
                                    </ptraptimer>
                                </Container>

                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column >
                        </Grid.Column>
                        <Grid.Column >
                            <Container textAlign='center' >
                                {showEventContents()}
                            </Container>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid.Row>

                    <Container textAlign='center' style={{ marginTop: -40, marginBottom: 0 }}>

                        <ptraspanintegertext className="notosansjpblack" style={{ color: '#1c3255' }}>
                            Enter an integer from -5 to 5:
                        </ptraspanintegertext>
                        <Ptraspaninvisibleintegertext >
                            abc
                        </Ptraspaninvisibleintegertext>

                        <Dropdown onChange={changePositionsFromModal} size='small' options={positionOptions}
                            selection
                            upward
                            placeholder='Position' style={{
                                textAlign: 'center',
                                justifyContent: 'center', align: 'center'
                            }} />

                    </Container>
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: "#ffffff" }}>

                    <Container textAlign='center' color='rgb(216,58,35)' >
                        {
                            showButtonLoading()
                        }
                    </Container>
                </Modal.Actions>

            </Modal>

            <Modal
                open={secondOpen}
                size='small'
            >
                <Modal.Content style={{ backgroundColor: "#ffffff" }}>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <Container textAlign='center' >
                                <ptraspaneventtitle className="notosansjpblack" style={{ color: '#ff3300' }}>
                                    Event Result
                                </ptraspaneventtitle>
                            </Container>
                        </Grid.Column>
                    </Grid>
                    <ptrae1>
                        <span className="notosansjpblack" style={{ color: '#1c3255', marginLeft: 50, marginRight: 50 }}>
                            {eventResult}
                        </span>
                    </ptrae1>
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: "#ffffff" }}>
                    <Container textAlign='center' color='rgb(216,58,35)'>
                        {
                            showButtonInSecondWaiting()
                        }
                    </Container>
                </Modal.Actions>
            </Modal>
        </Container>
    )
}
export default Trading;