import styled from 'styled-components';

export const ContentSectionColumn = styled.div`
width: 100%;
flex-direction: column;
display: flex;
flex-wrap: wrap-reverse;
justify-content: flex-start;
margin-top:24px
`

export const LHeading = styled.div` 
color: #213253;
font-size: 24px;
line-height: 130%;
`
export const CreateGameContainer = styled.div` 
margin-top: 20px;
 border-radius: 4px;
`

export const SHeading = styled.div`
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
color:#3D4043;
`

export const MHeading = styled.div`
color: #1C3255;
margin-bottom: 20px;
 margin-top: 20px;
font-size: 16px;
font-style: normal;
font-weight: 600;
`

export const GameHeading = styled.div`
font-size: 20px;
font-weight: 500px;
color: #1C3255;
text-transform: capitalize;
padding-left: 7px;
display: flex;
align-items: center; 
`

export const AccordionContainer = styled.div`
border-radius: 4px;
border: 1px solid #B9B9B9; 
padding: 16px;
`

export const CopyBox = styled.div`
display: flex;
height: 48px;
padding: 8px;
justify-content: flex-start;
align-content: center;
border-radius: 4px;
margin-top: 8px;
color: #1C3255;
align-items: center;
font-size: 16px;
`

export const ExportCopyBox = styled.div`
display: flex;
height: 48px;
padding: 8px;
justify-content: flex-start;
align-content: center;
border-radius: 4px;
margin-top: 8px;
color: #1C3255;
align-items: center;
text-decoration: underline;
font-size: 16px;
`

export const ActiveGamesHeading = styled.div`
background-color: #F0F6FC;
 height: 52px;
  color: #3D4043;
  font-size: 24px;
  align-items: center;
  padding-left: 16px; 
  display: flex; 
  border-radius: 4px; 
`

export const InputContainer = styled.div`
font-size: 16px;
display: flex;
flex-shrink: 1;
max-width: 280;
`

export const Input = styled.input`
border-radius: 4px 0px 0px 4px;
border: 1px solid  #B9B9B9;
font-size: 16px;
display: flex;
padding: 12px;
align-items: center;
font-weight: 400;
width: 100%;
line-height: 24px;
`

export const InputButton = styled.button`
background-color: #213253;
 height: 50px;
 color: white;
 align-items: center; 
 font-size: 16px;
  font-weight: 400; 
  font-family: Lato;
  justify-content: center;
   display: flex;
   padding: 8px;
   border-radius: 0px 4px 4px 0px;
    margin-left: -2px;
`

export const Table = styled.table`
width: 100%;
border: 1px solid #C8C8C8;
 border-radius: 4px; 
 border-collapse: 'separate';
  border-spacing: 0px;

  thead{
    background-color: #EDEDED;
     font-size: 16px; 
  }

  th{
    text-align: left;
       padding: 8px;
  }

  tbody{
    border: 1px solid #C8C8C8;
  }

  td{
    padding: 8px; 
    border-top:  1px solid #C8C8C8;
  }
  `