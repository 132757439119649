import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IntroPage from "./IntroPage";
import Trading from "./Trading";
import Thanks from "./Thanks";
import Leaderboard from "./Leaderboard";
import './index.css';
import Admin from "./Admin";
import ErrorPage from "./Error";


const App = () => {

  return (
    <Router>
      <div className="App" >
        <Routes>
          <Route index element={<IntroPage />} />
          <Route path='/Trading' element={<Trading />} />
          <Route path='/Thanks' element={<Thanks />} />
          <Route path='/Leaderboard' element={<Leaderboard />} />
          <Route path='/Admin' element={<Admin />} />
          <Route path='/Error' element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );

};

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";

document.head.appendChild(styleLink);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
