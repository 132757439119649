import styled from 'styled-components';

export const ErrorHeading = styled.div`
font-size: 28px;
 line-height: 130%;
 color: #3D4043;
 margin-bottom: 32px;
 margin-left: 20px;
 margin-right: 20px;
`;

export const ErorText = styled.div`
font-size: 16px;
 font-weight: 400;
 color: #F30;
 margin-left: 8px ;
`;

export const ErorInnerContainer = styled.div`
flex-direction: row;
 display: flex;
 margin-top: 40px;
 flex: 1;
`;